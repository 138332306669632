import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { logOut } from "../../utils/log-out";

const arrOption = [
  {
    id: 1,
    name: "Inicio",
    className: "",
    path: "/",
  },
];

const arrOptionAdmin = [
  {
    id: 2,
    path: "/users",
    name: "Usuarios",
    className: "",
  },
  {
    id: 5,
    path: "/bitacoras",
    name: "Historial de consultas",
    className: "",
  },
];

export default function OptionsSidebar() {
  const { data } = useSelector((state) => state.user.one);
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const history = useHistory();

  const objSesion = {
    id: 4,
    path: "logout",
    name: "Cerrar sesión",
    func: () => logOut(history, dispatch),
    className: "",
  };

  //eslint-disable-next-line
  const options =
    data?.role === "Admin"
      ? [...arrOption, ...arrOptionAdmin, objSesion]
      : [...arrOption, objSesion];

  return { user: data, options, pathname, history };
}
