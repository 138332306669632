import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTokenCtz } from "../../../redux/slices/citizen/_actions";
import { getAuth } from "../../utils/get-auth";

const MINUTE_MS = 3300000;
// const MINUTE_MS = 3000;

export default function AuthTokenCitizen({ children }) {
  const token = getAuth();
  const dispatch = useDispatch();
  const ctz = useSelector((state) => state.citizen.token);

  useEffect(() => {
    const interval = setInterval(async () => {
      token && dispatch(await getTokenCtz());
    }, MINUTE_MS);

    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fn = async () => {
      token && dispatch(await getTokenCtz());
    };
    fn();
    //eslint-disable-next-line
  }, []);

  if (ctz.status === "loading") return <>Obteniendo autorización...</>;
  return children;
}
