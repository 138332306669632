import { getAuth } from "../../utils/get-auth";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../../redux/slices/user/_actions";
import { logOut } from "../../../shared/utils/log-out";

export default function Auth({ children }) {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.user.one);

  const token = getAuth();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === "/login" && token) return history.push("/");
    if (Object.keys(data || {}).length && token) return;

    const fn = async () => {
      dispatch(await getUser());
    };

    token && fn();
    // eslint-disable-next-line
  }, [dispatch, location.pathname, token]);

  useEffect(() => {
    if (!token) return history.push("/login");
    if (status === "error") return logOut(history, dispatch);

    //eslint-disable-next-line
  }, [token, history, location.pathname, status]);
  if (status === "loading") return <>Loading....</>;
  return children;
}
