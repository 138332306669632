import { createSlice } from "@reduxjs/toolkit";
import { getTokenCtz, getFullInfo } from "./_actions";
import {
  reducerGenerator,
  initialState,
} from "../../../shared/utils/reducer-generator";

const arrReducers = [
  { action: getTokenCtz, key: "token" },
  { action: getFullInfo, key: "one" },
];
const reducers = reducerGenerator(arrReducers);

const citizen = createSlice({
  name: "citizen",
  initialState,
  extraReducers: {
    ...reducers,
  },
});

export default citizen.reducer;
