import { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";

import routes from "./routes";
import store from "./redux";

import Auth from "./shared/components/hocs/auth";
import AuthTokenCitizen from "./shared/components/hocs/auth-citizen";
import Sidebar from "./shared/components/hocs/sidebar";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Auth>
          <AuthTokenCitizen>
            <Switch>
              <Sidebar>
                <Suspense fallback={<>Loading... </>}>
                  <Toaster position="top-right" reverseOrder={false} />
                  {routes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      exact={route.exact}
                    >
                      <route.component />
                    </Route>
                  ))}
                </Suspense>
              </Sidebar>
            </Switch>
          </AuthTokenCitizen>
        </Auth>
      </Router>
    </Provider>
  );
}

export default App;
