import { cutomAxios } from "../utils/axios";

export const getCitizenToken = async () => {
  const {
    data: { data },
  } = await cutomAxios().get("/citizens/getCitizenToken");
  return data?.citizen_token;
};

export const getCitizenFull = async ({ token: tokenCTZ, cedula }) => {
  const form = new FormData();
  form.append("citizen_token", tokenCTZ);
  form.append("cedula", cedula);
  const { data } = await cutomAxios().post(`/citizens/getFullInfo`, form);
  return data.data;
};
