import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCitizenToken,
  getCitizenFull,
} from "../../../shared/services/citizen";

export const getTokenCtz = createAsyncThunk(
  "citizen/token",
  async () => await getCitizenToken()
);

export const getFullInfo = createAsyncThunk(
  "citizen/one",
  ({ token, cedula }) => getCitizenFull({ token, cedula })
);
