import axios from "axios";
import { getAuth } from "./get-auth";

export function cutomAxios() {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${getAuth()}`,
    },
  });
}
