import { configureStore } from "@reduxjs/toolkit";

import user from "./slices/user/_reducers";
import citizen from "./slices/citizen/_reducers";
import bitacora from "./slices/bitacora/_reducers";

export default configureStore({
  reducer: {
    user,
    citizen,
    bitacora,
  },
});
