import signUp from "./pages/sign-up/route";
import login from "./pages/login/route";
import home from "./pages/home/route";
import profile from "./pages/profile/route";
import users from "./pages/users/route";
import bitacora from "./pages/bitacora/route";
import page404 from "./pages/404/route";

const routes = [signUp, login, home, profile, users, bitacora, page404];
export default routes;
