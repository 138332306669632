import { lazy } from "react";

const UserList = lazy(() => import("./index"));
const route = {
  path: "/users",
  exact: true,
  component: UserList,
};

export default route;
