import { lazy } from "react";

const Bitacora = lazy(() => import("./index"));

const route = {
  exact: true,
  path: "/bitacoras",
  component: Bitacora,
};

export default route;
