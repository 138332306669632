import { lazy } from "react";
const Profile = lazy(() => import("./index"));

const route = {
  exact: true,
  path: "/profile",
  component: Profile,
};

export default route;
