import { lazy } from "react";
const CPM = lazy(() => import("./index"));

const route = {
  component: CPM,
  path: "/404",
  exact: true,
};

export default route;
