import { lazy } from "react";

const SignUp = lazy(() => import("./index"));

const route = {
  component: SignUp,
  path: "/sign-up",
  exact: true,
};
export default route;
