import { lazy } from "react";

const Home = lazy(() => import("./index"));
const route = {
  path: "/",
  component: Home,
  exact: true,
};

export default route;
