import { createSlice } from "@reduxjs/toolkit";
import { setBitacoras } from "./_actions";
import {
  initialState,
  fulfilled,
} from "../../../shared/utils/reducer-generator";

const bitacora = createSlice({
  name: "bitacora",
  initialState,
  extraReducers: {
    [setBitacoras]: fulfilled({ key: "all" }),
  },
});

export default bitacora.reducer;
