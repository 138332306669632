import PP from "../../../img/pp.png";

const HeaderUserOption = ({ user, history }) => {
  return (
    <div className="border-b-2 ">
      <span
        className="flex px-2 pb-5 pt-2  cursor-pointer "
        onClick={() => history.push("/profile")}
      >
        <img
          className="w-10 border border-black rounded-3xl outline-none"
          src={user.profile?.image || PP}
          alt=""
        />
        <div className="self-center ">
          <p className="font-bold text-sm  ml-1"> {user.profile?.name} </p>
          <p className="text-gray-500 text-xs self-center ml-1"> {user.role}</p>
        </div>
      </span>
    </div>
  );
};

export default HeaderUserOption;
