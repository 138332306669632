import Block from "../UI/block";
import HeaderUserOption from "../UI/header-user-option";

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logOut } from "../../utils/log-out";

import optionsSidebar from "../hooks/options";

export default function Sidebar({ children }) {
  const dispatch = useDispatch();

  const { user, options, pathname, history } = optionsSidebar();
  const [active, setActive] = useState(pathname);
  const [name, setName] = useState(pathname);

  useEffect(() => {
    setActive(pathname);
    setName(options.find((it) => it.path === pathname)?.name || "");
    //eslint-disable-next-line
  }, [pathname, options]);

  return (
    <div className="flex ">
      {pathname !== "/login" && (
        <div className="w-72 h-auto shadow-xl boder-l-2 hidden md:inline-block">
          <div className="mt-4">
            <HeaderUserOption user={user} history={history} />
          </div>
          {options.map((item) => (
            <div
              key={item.id}
              className={`border-b-2 p-5 cursor-pointer hover:bg-gray-100 ${
                item.path === active && "bg-gray-100"
              }`}
              onClick={() =>
                item.id === 4
                  ? logOut(history, dispatch)
                  : history.push(item.path)
              }
            >
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      )}
      <div className="w-full h-full">
        <div className="mb-2">
          <Block />
          {name && (
            <div className="w-full bg-gray-100 shadow ">
              <p className="px-4 py-2 text-gray-800">{name}</p>
            </div>
          )}
        </div>
        <div className="w-full h-screen px-0 sm:px-4 box-border">
          {children}
        </div>
      </div>
    </div>
  );
}
