import { cutomAxios } from "../utils/axios";
import { getAuth } from "../utils/get-auth";

export const getprofile = async () => {
  const { data } = await cutomAxios().get("/profile/getProfile");
  if (data.message) throw Error("Error");
  return data.data;
};

export const createUser = async (payload) => {
  const token = getAuth();
  if (!token) return;
  const form = new FormData();
  Object.entries(payload).map((item) => form.append(item[0], item[1]));
  const res = await fetch(`${process.env.REACT_APP_API_URL}/users/store`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
    },
    body: form,
  });

  const { data } = await res.json();
  return data;
};

export const updatePassword = async (payload) => {
  const token = getAuth();
  if (!token) return;
  const form = new FormData();
  Object.entries(payload).map((item) => form.append(item[0], item[1]));
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/profile/changepassword`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: form,
    }
  );
  const data = await res.json();
  if (data.status === "error") throw Error(data.message);
  return data;
};

export const getUsers = async () => {
  const token = getAuth();
  if (!token) return;

  const res = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
    },
  });
  const { data } = await res.json();
  return data;
};

export const setActiveUser = async (id) => {
  try {
    const token = getAuth();
    if (!token) return;
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/users/${id}/activate`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      }
    );
    const { data } = await res.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const resetPassUser = async (id) => {
  const token = getAuth();
  if (!token) return;

  const body = new FormData();
  body.append("password", "firmad");
  body.append("password_confirmation", "firmad");

  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/users/${id}/resetpassword`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body,
    }
  );
  const { data } = await res.json();
  return data;
};

export const updateUser = async (payload) => {
  try {
    const body = new FormData();
    Object.entries(payload).map((it) => body.append(it[0], it[1]));
    const { data } = await cutomAxios().post("/profile/update", body);
    return data.data;
  } catch (error) {
    console.log(error, error.response);
    throw error;
  }
};
