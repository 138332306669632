import { createSlice } from "@reduxjs/toolkit";
import {
  getUser,
  setUser,
  getUsers,
  concatUserByAll,
  setUsers,
} from "./_actions";
import {
  reducerGenerator,
  initialState,
} from "../../../shared/utils/reducer-generator";

const arrReducers = [
  { action: getUser, key: "one" },
  { action: getUsers, key: "all" },
];
const reducers = reducerGenerator(arrReducers);

const user = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    ...reducers,
    [setUser]: (state, { payload }) => ({
      ...state,
      one: {
        data: payload,
        status: "completed",
      },
    }),
    [concatUserByAll]: (state, { payload }) => ({
      ...state,
      all: { data: [payload, ...state.all.data], status: "completed" },
    }),
    [setUsers]: (state, { payload }) => ({
      ...state,
      all: {
        data: payload,
        status: "completed",
      },
    }),
  },
});

export default user.reducer;
