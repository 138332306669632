import { lazy } from "react";

const Login = lazy(() => import("./index"));

const route = {
  path: "/login",
  component: Login,
  exact: true,
};

export default route;
