import Logo from "../../../../shared/img/ogtic.png";
import OptionsPopover from "../popover";
import optionsSidebar from "../../hooks/options";
import HeaderUser from "../header-user-option";

export default function Block() {
  const { user, options, history, pathname } = optionsSidebar();

  return (
    <div className="w-full h-auto flex justify-between md:justify-end  bg-white border">
      <div
        className={` ${
          pathname === "/login"
            ? "hidden"
            : " m-0 sm:ml-10 self-center flex md:hidden "
        }`}
      >
        <OptionsPopover
          options={options}
          history={history}
          pathname={pathname}
          header={<HeaderUser user={user} history={history} />}
        />
      </div>
      <img src={Logo} className="w-32 sm:w-40 p-5" alt="logo" />
    </div>
  );
}
