import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getprofile, getUsers as gtUsers } from "../../../shared/services/user";

export const setUser = createAction("user/one");
export const setUsers = createAction("user/all");
export const concatUserByAll = createAction("user/all");

export const getUser = createAsyncThunk(
  "user/one",
  async () => await getprofile()
);

export const getUsers = createAsyncThunk("user/all", async () => gtUsers());
